import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _aac10834 = () => interopDefault(import('../uicore/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _bdeddbb4 = () => interopDefault(import('../uicore/pages/base/check_doc/_fileId.vue' /* webpackChunkName: "pages/base/check_doc/_fileId" */))
const _318bf250 = () => interopDefault(import('../uicore/pages/arm/resetPass/_token.vue' /* webpackChunkName: "pages/arm/resetPass/_token" */))
const _6899ddbf = () => interopDefault(import('../uicore/pages/base/service/list/_parentId.vue' /* webpackChunkName: "pages/base/service/list/_parentId" */))
const _21f77e58 = () => interopDefault(import('../uicore/pages/base/service/info/_tsrId.vue' /* webpackChunkName: "pages/base/service/info/_tsrId" */))
const _201be364 = () => interopDefault(import('../uicore/pages/base/auth/resetPass/_token.vue' /* webpackChunkName: "pages/base/auth/resetPass/_token" */))
const _547cbf0b = () => interopDefault(import('../uicore/pages/base/application/process/_asId.vue' /* webpackChunkName: "pages/base/application/process/_asId" */))
const _5b707aa3 = () => interopDefault(import('../uicore/pages/base/application/monitor/_appId.vue' /* webpackChunkName: "pages/base/application/monitor/_appId" */))
const _afa93a48 = () => interopDefault(import('../uicore/pages/arm/service/list/_parentId.vue' /* webpackChunkName: "pages/arm/service/list/_parentId" */))
const _0da518d7 = () => interopDefault(import('../uicore/pages/arm/service/info/_tsrId.vue' /* webpackChunkName: "pages/arm/service/info/_tsrId" */))
const _48636e64 = () => interopDefault(import('../uicore/pages/arm/application/process/_asId.vue' /* webpackChunkName: "pages/arm/application/process/_asId" */))
const _835fad80 = () => interopDefault(import('../uicore/pages/arm/application/monitor/_appId.vue' /* webpackChunkName: "pages/arm/application/monitor/_appId" */))
const _401e3298 = () => interopDefault(import('../uicore/pages/arm/admin/org/ws/_tsrId.vue' /* webpackChunkName: "pages/arm/admin/org/ws/_tsrId" */))
const _258d328c = () => interopDefault(import('../uicore/pages/arm/admin/org/ws/employee/_empId.vue' /* webpackChunkName: "pages/arm/admin/org/ws/employee/_empId" */))
const _28b1871b = () => interopDefault(import('../uicore/pages/base/application/result/appId.vue' /* webpackChunkName: "pages/base/application/result/appId" */))
const _abbdcf8e = () => interopDefault(import('../uicore/pages/arm/application/view/asId.vue' /* webpackChunkName: "pages/arm/application/view/asId" */))
const _1c849658 = () => interopDefault(import('../uicore/pages/arm/admin/system/route.vue' /* webpackChunkName: "pages/arm/admin/system/route" */))
const _3b3302e0 = () => interopDefault(import('../uicore/pages/arm/admin/system/registryLayers.vue' /* webpackChunkName: "pages/arm/admin/system/registryLayers" */))
const _40394b5f = () => interopDefault(import('../uicore/pages/arm/admin/system/list.vue' /* webpackChunkName: "pages/arm/admin/system/list" */))
const _3e7957c3 = () => interopDefault(import('../uicore/pages/arm/admin/system/layers.vue' /* webpackChunkName: "pages/arm/admin/system/layers" */))
const _6461fdac = () => interopDefault(import('../uicore/pages/arm/admin/system/control.vue' /* webpackChunkName: "pages/arm/admin/system/control" */))
const _7635897f = () => interopDefault(import('../uicore/pages/arm/admin/system/calendar.vue' /* webpackChunkName: "pages/arm/admin/system/calendar" */))
const _5fce7820 = () => interopDefault(import('../uicore/pages/arm/admin/system/app.vue' /* webpackChunkName: "pages/arm/admin/system/app" */))
const _a501412a = () => interopDefault(import('../uicore/pages/arm/admin/org/service.vue' /* webpackChunkName: "pages/arm/admin/org/service" */))
const _eedb4530 = () => interopDefault(import('../uicore/pages/arm/admin/org/employee.vue' /* webpackChunkName: "pages/arm/admin/org/employee" */))
const _34e67bd7 = () => interopDefault(import('../uicore/pages/arm/admin/org/app.vue' /* webpackChunkName: "pages/arm/admin/org/app" */))
const _608f6508 = () => interopDefault(import('../uicore/pages/base/auth/register.vue' /* webpackChunkName: "pages/base/auth/register" */))
const _51c35658 = () => interopDefault(import('../uicore/pages/base/auth/profile.vue' /* webpackChunkName: "pages/base/auth/profile" */))
const _ddb5f958 = () => interopDefault(import('../uicore/pages/base/auth/login.vue' /* webpackChunkName: "pages/base/auth/login" */))
const _0930ef0e = () => interopDefault(import('../uicore/pages/base/auth/forgotPass.vue' /* webpackChunkName: "pages/base/auth/forgotPass" */))
const _5f9577e2 = () => interopDefault(import('../uicore/pages/base/application/list.vue' /* webpackChunkName: "pages/base/application/list" */))
const _2fb7ba28 = () => interopDefault(import('../uicore/pages/arm/application/list.vue' /* webpackChunkName: "pages/arm/application/list" */))
const _5c00c0f7 = () => interopDefault(import('../uicore/pages/arm/application/income.vue' /* webpackChunkName: "pages/arm/application/income" */))
const _5f90bae2 = () => interopDefault(import('../uicore/pages/oldAppsList/employee.vue' /* webpackChunkName: "pages/oldAppsList/employee" */))
const _6fd12904 = () => interopDefault(import('../uicore/pages/oldAppsList/applicant.vue' /* webpackChunkName: "pages/oldAppsList/applicant" */))
const _0bd23fa6 = () => interopDefault(import('../uicore/pages/base/registries.vue' /* webpackChunkName: "pages/base/registries" */))
const _5b2b749e = () => interopDefault(import('../uicore/pages/base/geoportal/index.vue' /* webpackChunkName: "pages/base/geoportal/index" */))
const _21abeb18 = () => interopDefault(import('../uicore/pages/base/faq.vue' /* webpackChunkName: "pages/base/faq" */))
const _6ad968ca = () => interopDefault(import('../uicore/pages/arm/registries.vue' /* webpackChunkName: "pages/arm/registries" */))
const _4244b90a = () => interopDefault(import('../uicore/pages/arm/profile.vue' /* webpackChunkName: "pages/arm/profile" */))
const _983a05ec = () => interopDefault(import('../uicore/pages/arm/login.vue' /* webpackChunkName: "pages/arm/login" */))
const _32a6c621 = () => interopDefault(import('../uicore/pages/arm/geoportal/index.vue' /* webpackChunkName: "pages/arm/geoportal/index" */))
const _240f3083 = () => interopDefault(import('../uicore/pages/arm/forgotPass.vue' /* webpackChunkName: "pages/arm/forgotPass" */))
const _f7256d64 = () => interopDefault(import('../uicore/pages/Test.vue' /* webpackChunkName: "pages/Test" */))
const _7e3390b0 = () => interopDefault(import('../uicore/pages/base/index.vue' /* webpackChunkName: "pages/base/index" */))
const _2b894c1a = () => interopDefault(import('../uicore/pages/arm/index.vue' /* webpackChunkName: "pages/arm/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/",
    component: _aac10834,
    name: "index"
  }, {
    path: "/base/check_doc/:fileId?",
    component: _bdeddbb4,
    name: "base-check_doc-fileId"
  }, {
    path: "/arm/resetPass/:token?",
    component: _318bf250,
    name: "arm-resetPass-token"
  }, {
    path: "/base/service/list/:parentId?",
    component: _6899ddbf,
    name: "base-service-list-parentId"
  }, {
    path: "/base/service/info/:tsrId?",
    component: _21f77e58,
    name: "base-service-info-tsrId"
  }, {
    path: "/base/auth/resetPass/:token?",
    component: _201be364,
    name: "base-auth-resetPass-token"
  }, {
    path: "/base/application/process/:asId?",
    component: _547cbf0b,
    name: "base-application-process-asId"
  }, {
    path: "/base/application/monitor/:appId?",
    component: _5b707aa3,
    name: "base-application-monitor-appId"
  }, {
    path: "/arm/service/list/:parentId?",
    component: _afa93a48,
    name: "arm-service-list-parentId"
  }, {
    path: "/arm/service/info/:tsrId?",
    component: _0da518d7,
    name: "arm-service-info-tsrId"
  }, {
    path: "/arm/application/process/:asId?",
    component: _48636e64,
    name: "arm-application-process-asId"
  }, {
    path: "/arm/application/monitor/:appId?",
    component: _835fad80,
    name: "arm-application-monitor-appId"
  }, {
    path: "/arm/admin/org/ws/:tsrId?",
    component: _401e3298,
    name: "arm-admin-org-ws-tsrId"
  }, {
    path: "/arm/admin/org/ws/employee/:empId?",
    component: _258d328c,
    name: "arm-admin-org-ws-employee-empId"
  }, {
    path: "/base/application/result/appId",
    component: _28b1871b,
    name: "base-application-result-appId"
  }, {
    path: "/arm/application/view/asId",
    component: _abbdcf8e,
    name: "arm-application-view-asId"
  }, {
    path: "/arm/admin/system/route",
    component: _1c849658,
    name: "arm-admin-system-route"
  }, {
    path: "/arm/admin/system/registryLayers",
    component: _3b3302e0,
    name: "arm-admin-system-registryLayers"
  }, {
    path: "/arm/admin/system/list",
    component: _40394b5f,
    name: "arm-admin-system-list"
  }, {
    path: "/arm/admin/system/layers",
    component: _3e7957c3,
    name: "arm-admin-system-layers"
  }, {
    path: "/arm/admin/system/control",
    component: _6461fdac,
    name: "arm-admin-system-control"
  }, {
    path: "/arm/admin/system/calendar",
    component: _7635897f,
    name: "arm-admin-system-calendar"
  }, {
    path: "/arm/admin/system/app",
    component: _5fce7820,
    name: "arm-admin-system-app"
  }, {
    path: "/arm/admin/org/service",
    component: _a501412a,
    name: "arm-admin-org-service"
  }, {
    path: "/arm/admin/org/employee",
    component: _eedb4530,
    name: "arm-admin-org-employee"
  }, {
    path: "/arm/admin/org/app",
    component: _34e67bd7,
    name: "arm-admin-org-app"
  }, {
    path: "/base/auth/register",
    component: _608f6508,
    name: "base-auth-register"
  }, {
    path: "/base/auth/profile",
    component: _51c35658,
    name: "base-auth-profile"
  }, {
    path: "/base/auth/login",
    component: _ddb5f958,
    name: "base-auth-login"
  }, {
    path: "/base/auth/forgotPass",
    component: _0930ef0e,
    name: "base-auth-forgotPass"
  }, {
    path: "/base/application/list",
    component: _5f9577e2,
    name: "base-application-list"
  }, {
    path: "/arm/application/list",
    component: _2fb7ba28,
    name: "arm-application-list"
  }, {
    path: "/arm/application/income",
    component: _5c00c0f7,
    name: "arm-application-income"
  }, {
    path: "/oldAppsList/employee",
    component: _5f90bae2,
    name: "oldAppsList-employee"
  }, {
    path: "/oldAppsList/applicant",
    component: _6fd12904,
    name: "oldAppsList-applicant"
  }, {
    path: "/base/registries",
    component: _0bd23fa6,
    name: "base-registries"
  }, {
    path: "/base/geoportal",
    component: _5b2b749e,
    name: "base-geoportal"
  }, {
    path: "/base/faq",
    component: _21abeb18,
    name: "base-faq"
  }, {
    path: "/arm/registries",
    component: _6ad968ca,
    name: "arm-registries"
  }, {
    path: "/arm/profile",
    component: _4244b90a,
    name: "arm-profile"
  }, {
    path: "/arm/login",
    component: _983a05ec,
    name: "arm-login"
  }, {
    path: "/arm/geoportal",
    component: _32a6c621,
    name: "arm-geoportal"
  }, {
    path: "/arm/forgotPass",
    component: _240f3083,
    name: "arm-forgotPass"
  }, {
    path: "/Test",
    component: _f7256d64,
    name: "Test"
  }, {
    path: "/base",
    component: _7e3390b0,
    name: "base"
  }, {
    path: "/arm",
    component: _2b894c1a,
    name: "arm"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
